import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Avatar } from "antd";
import { Container, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

// icons
import { BiDesktop } from "react-icons/bi";
import { BsHeadphones } from "react-icons/bs";
import { GiVirtualMarker } from "react-icons/gi";
import { SiBlockchaindotcom, SiMarketo } from "react-icons/si";
import { VscRadioTower } from "react-icons/vsc";

// local imports
import styles from "./styles/styles";

function About(props) {
    const classes = styles();
    const { t } = useTranslation();
    const trans = (name) => t(`about.${name}`);

    return (
        <div id="about-us" className={classes.aboutCont}>
            <Container maxWidth="xl">
                <div>
                    <ScrollAnimation animateIn="fadeInUp" duration={1}>
                        <Typography
                            variant="h4"
                            align="left"
                            className={classes.aboutHeader}
                        >
                            <span className={classes.font700}>
                                {trans("highlightTitle")}
                            </span>{" "}
                            {trans("title")}
                        </Typography>
                        <div className={classes.aboutBorder} />
                    </ScrollAnimation>

                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={5}>
                            <div className="mt-4 pt-4">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    duration={1}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.white}
                                        align="left"
                                    >
                                        {trans("description1")}
                                    </Typography>
                                    <Typography
                                        className={classes.aboutDesc}
                                        align="left"
                                        variant="subtitle1"
                                    >
                                        {trans("description2")}
                                    </Typography>
                                </ScrollAnimation>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={7}>
                            <ScrollAnimation animateIn="fadeInUp" duration={1}>
                                <Grid container className="mt-3">
                                    <Grid item xs={4} sm={4}>
                                        <a
                                            href="https://www.wylog.com/services/blockchain-solutions/"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className="text-decoration-none"
                                        >
                                            <Avatar
                                                className={classes.solAvatar}
                                                size={70}
                                            >
                                                <SiBlockchaindotcom
                                                    className={classes.solIcon}
                                                />
                                            </Avatar>
                                            <Typography
                                                variant="h6"
                                                className={classes.solution}
                                            >
                                                {trans("services.a")}
                                            </Typography>
                                        </a>
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <a
                                            href="https://www.wylog.com/services/ar-vr-solutions/"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className="text-decoration-none"
                                        >
                                            <Avatar
                                                size={70}
                                                className={classes.solAvatar}
                                            >
                                                <GiVirtualMarker
                                                    className={classes.solIcon}
                                                />
                                            </Avatar>
                                            <Typography
                                                variant="h6"
                                                className={classes.solution}
                                            >
                                                {trans("services.b")}
                                            </Typography>
                                        </a>
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <a
                                            href="https://www.wylog.com/services/web-and-mobile-applications/"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className="text-decoration-none"
                                        >
                                            <Avatar
                                                size={70}
                                                className={classes.solAvatar}
                                            >
                                                <BiDesktop
                                                    className={classes.solIcon}
                                                />
                                            </Avatar>

                                            <Typography
                                                variant="h6"
                                                className={classes.solution}
                                            >
                                                {trans("services.c")}
                                            </Typography>
                                        </a>
                                    </Grid>
                                </Grid>
                            </ScrollAnimation>

                            <ScrollAnimation animateIn="fadeInUp" duration={1}>
                                <Grid container className={classes.services}>
                                    <Grid item xs={4} sm={4}>
                                        <a
                                            href="https://www.wylog.com/services/iot-solutions/"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className="text-decoration-none"
                                        >
                                            <Avatar
                                                size={70}
                                                className={classes.solAvatar}
                                            >
                                                <VscRadioTower
                                                    className={classes.solIcon}
                                                />
                                            </Avatar>

                                            <Typography
                                                variant="h6"
                                                className={classes.solution}
                                            >
                                                {trans("services.d")}
                                            </Typography>
                                        </a>
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <a
                                            href="https://www.wylog.com/services/marketing-services/"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className="text-decoration-none"
                                        >
                                            <Avatar
                                                size={70}
                                                className={classes.solAvatar}
                                            >
                                                <SiMarketo
                                                    className={classes.solIcon}
                                                />
                                            </Avatar>

                                            <Typography
                                                variant="h6"
                                                className={classes.solution}
                                            >
                                                {trans("services.e")}
                                            </Typography>
                                        </a>
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <a
                                            href="https://www.wylog.com/services/managed-helpdesk/"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className="text-decoration-none"
                                        >
                                            <Avatar
                                                size={70}
                                                className={classes.solAvatar}
                                            >
                                                <BsHeadphones
                                                    className={classes.solIcon}
                                                />
                                            </Avatar>

                                            <Typography
                                                variant="h6"
                                                className={classes.solution}
                                            >
                                                {trans("services.f")}
                                            </Typography>
                                        </a>
                                    </Grid>
                                </Grid>
                            </ScrollAnimation>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    );
}

export default About;
