import { ApolloClient, InMemoryCache } from "@apollo/client";

const createClient = () => {
    const uri = "http://localhost:8000/api/graphql";
    const cache = new InMemoryCache();

    return new ApolloClient({ uri, cache });
};

export default createClient;
