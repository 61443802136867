import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Carousel } from "antd";
import { Container, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

// local imports
import bg1 from "../images/home-bg1.png";
import bg2 from "../images/home-bg2.png";
import styles from "./styles/styles";
import { Header } from "./common-components";

function Home(props) {
    const classes = styles();
    const { t } = useTranslation();
    const trans = (name) => t(`home.${name}`);

    return (
        <div id="home" className={classes.homeCont}>
            <Header />
            <Container maxWidth="xl">
                <Carousel autoplay className="px-4" dots={true}>
                    <div>
                        <div className={classes.carousel}>
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Grid item xs={12} sm={6}>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        duration={1}
                                    >
                                        <Typography
                                            variant="h3"
                                            className={classes.slide1}
                                            align="left"
                                        >
                                            {`${trans("slide1.title")} `}
                                            <span className={"home-text"}>
                                                {trans("slide1.highlightTitle")}
                                            </span>
                                            {` ${trans(
                                                "slide1.titleExtension"
                                            )}`}
                                        </Typography>
                                    </ScrollAnimation>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className="d-flex justify-content-end"
                                >
                                    <ScrollAnimation
                                        animateIn="fadeInDown"
                                        duration={1}
                                    >
                                        <img
                                            src={bg1}
                                            alt="background-1"
                                            className={classes.homebg1}
                                        />
                                    </ScrollAnimation>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div>
                        <div className={classes.carousel}>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item sm={6}>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        duration={1}
                                    >
                                        <Typography
                                            variant="h3"
                                            className={classes.slide2}
                                            align="left"
                                        >
                                            {`${trans("slide2.title")} `}
                                            <span className={"home-text"}>
                                                {`${trans(
                                                    "slide2.highlightTitle"
                                                )} `}
                                            </span>
                                            {trans("slide2.titleExtension")}
                                        </Typography>
                                    </ScrollAnimation>
                                </Grid>
                                <Grid
                                    item
                                    sm={6}
                                    className="d-flex justify-content-end"
                                >
                                    <ScrollAnimation
                                        animateIn="fadeInDown"
                                        duration={1}
                                    >
                                        <img
                                            src={bg2}
                                            alt="background-2"
                                            className={classes.homebg1}
                                        />
                                    </ScrollAnimation>
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                    <div>
                        <div className={classes.carousel}>
                            <Grid container alignItems="center" spacing={4}>
                                <Grid item sm={6}>
                                    <ScrollAnimation
                                        animateIn="fadeIn"
                                        duration={1}
                                    >
                                        <Typography
                                            variant="h3"
                                            className={classes.slide3}
                                            align="left"
                                        >
                                            {`${trans("slide3.title")} `}
                                            <span className={"home-text"}>
                                                {`${trans(
                                                    "slide3.highlightTitle"
                                                )} `}
                                            </span>
                                            {`${trans(
                                                "slide3.titleExtension"
                                            )}`}
                                        </Typography>

                                        <Typography
                                            variant="subtitle1"
                                            className={classes.slide3Desc}
                                            align="left"
                                        >
                                            {trans("slide3.description")}
                                        </Typography>
                                    </ScrollAnimation>
                                </Grid>
                                <Grid
                                    item
                                    sm={6}
                                    className="d-flex justify-content-end"
                                >
                                    <ScrollAnimation
                                        animateIn="fadeInDown"
                                        duration={1}
                                    >
                                        <img
                                            src={bg1}
                                            alt="background-1"
                                            className={classes.homebg1}
                                        />
                                    </ScrollAnimation>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Carousel>
            </Container>
        </div>
    );
}

export default Home;
