import React from "react";
import clsx from "clsx";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// local imports
import blobsvg from "../images/haikei.svg";
import intergration from "../images/integration.png";
import styles from "./styles/styles";

function Integration(props) {
    const classes = styles();
    const { t } = useTranslation();

    const trans = (name) => t(`integration.${name}`);

    return (
        <div
            id="integration"
            className={classes.integrationCont}
            style={{
                backgroundImage: `url(${blobsvg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}
        >
            <Container maxWidth="lg" className="d-flex align-items-center">
                <Grid
                    container
                    spacing={4}
                    alignItems="center"
                    className={classes.integrationGrid}
                >
                    <Grid item xs={12} sm={6} className={classes.apiSmUp}>
                        <ScrollAnimation animateIn="fadeIn" duration={1}>
                            <img
                                src={intergration}
                                alt="integration"
                                height="340px"
                                width="auto"
                                className={classes.api}
                            />
                        </ScrollAnimation>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ScrollAnimation animateIn="fadeInUp" duration={1}>
                            <Typography
                                className={clsx(
                                    classes.integratioHeader,
                                    "font-weight-bold"
                                )}
                                variant="h4"
                                align="left"
                                style={{ fontWeight: 700 }}
                            >
                                {trans("title")}
                            </Typography>
                            <div className={classes.integBorder} />
                        </ScrollAnimation>

                        <ScrollAnimation
                            animateIn="fadeInUp"
                            duration={1}
                            delay={200}
                        >
                            <Typography
                                className={clsx(classes.integration)}
                                variant="subtitle1"
                                align="left"
                            >
                                {trans("description1")}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                align="left"
                                className={clsx(classes.integration)}
                            >
                                {trans("description2")}
                            </Typography>
                        </ScrollAnimation>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.apiXs}>
                        <ScrollAnimation animateIn="fadeInLeft" duration={1}>
                            <img
                                src={intergration}
                                alt="integration"
                                height="340px"
                                width="auto"
                                className={classes.api}
                            />
                        </ScrollAnimation>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default Integration;
