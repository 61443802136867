import React from "react";
import { BackTop, Avatar, Tooltip } from "antd";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import styles from "../styles/styles";

function BackToTop() {
    const classes = styles();
    return (
        <Tooltip title="Back to top" className={classes.backTop}>
            <BackTop>
                <Avatar size={60} className={classes.arrowAvatar}>
                    <MdOutlineKeyboardArrowUp fontSize={30} />
                </Avatar>
            </BackTop>
        </Tooltip>
    );
}

export default BackToTop;
