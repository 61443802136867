import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Button, Input } from "antd";
import { Container, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

// local imports
import contactsVector from "../images/contact.png";
import styles from "./styles/styles";

const { TextArea } = Input;

function Contact(props) {
    const classes = styles();
    const { t } = useTranslation();
    const trans = (name) => t(`contact.${name}`);

    return (
        <div id="contact-us" className="pt-2 pb-4">
            <Container maxWidth="lg" className="my-4 pb-4">
                <Grid
                    container
                    spacing={4}
                    className="my-4"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={7} md={6}>
                        <ScrollAnimation animateIn="fadeInUp" duration={1}>
                            <Typography
                                variant="h4"
                                align="left"
                                className={classes.contactHeader}
                            >
                                {trans("title")}{" "}
                                <b> {trans("highlightTitle")} </b>
                            </Typography>
                            <div className={classes.headerBorder} />
                        </ScrollAnimation>

                        <ScrollAnimation
                            animateIn="fadeInUp"
                            duration={1}
                            delay={200}
                        >
                            <Typography
                                variant="subtitle1"
                                align="left"
                                className={classes.contactSecondary}
                            >
                                {trans("description")}
                            </Typography>
                        </ScrollAnimation>

                        <ScrollAnimation
                            animateIn="fadeInUp"
                            duration={1}
                            delay={220}
                        >
                            <Grid container spacing={3} className="mt-4">
                                <Grid item xs={12}>
                                    <Input
                                        size="large"
                                        placeholder={trans("form.name")}
                                        bordered={false}
                                        style={{ background: "#F8F9F8" }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input
                                        size="large"
                                        placeholder={trans("form.company")}
                                        bordered={false}
                                        style={{ background: "#F8F9F8" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        size="large"
                                        bordered={false}
                                        placeholder={trans("form.email")}
                                        style={{ background: "#F8F9F8" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        size="large"
                                        bordered={false}
                                        placeholder={trans("form.phone")}
                                        style={{ background: "#F8F9F8" }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextArea
                                        bordered={false}
                                        placeholder={trans("form.message")}
                                        style={{ background: "#F8F9F8" }}
                                        rows={5}
                                        maxLength={8}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className="d-flex justify-content-start"
                                >
                                    <Button
                                        size="large"
                                        className={classes.contactBtn}
                                    >
                                        {trans("form.send")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </ScrollAnimation>
                    </Grid>
                    <Grid item xs={12} sm={5} md={6}>
                        <ScrollAnimation
                            animateIn="fadeIn"
                            duration={1}
                            offset={20}
                        >
                            <img
                                src={contactsVector}
                                alt="integration"
                                className={classes.contactImg}
                            />
                        </ScrollAnimation>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default Contact;
