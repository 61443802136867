import React from "react";
import { AppBar, Box, Container, Toolbar } from "@mui/material";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { CgMenu } from "react-icons/cg";

// local imports
import Language from "./Language";
import logo from "../../images/logo.png";
import styles from "../styles/styles";

function Header(props) {
    const classes = styles();
    const { t } = useTranslation();

    return (
        <Container maxWidth="xl" className="py-3">
            <Box sx={{ flexGrow: 1 }}>
                <AppBar
                    className={classes.appbar}
                    position="static"
                    elevation={0}
                >
                    <Toolbar className="d-flex justify-content-between">
                        <img src={logo} alt="logo" />

                        <div className={classes.menus}>
                            {[
                                "home",
                                "features",
                                "interface",
                                "integration",
                                "technical",
                                "pricing",
                                "services",
                                "contact",
                                "about",
                            ].map((menu, idx) => (
                                <span key={idx}>
                                    <Button
                                        type="link"
                                        className={classes.menu}
                                        size="middle"
                                        href={`#${
                                            menu === "interface"
                                                ? "user-interface"
                                                : menu === "contact" ||
                                                  menu === "about"
                                                ? `${menu}-us`
                                                : menu
                                        }`}
                                    >
                                        {t(`header.${menu}`)}
                                    </Button>
                                </span>
                            ))}
                            <Language />
                        </div>
                        <div className={classes.menuBtn}>
                            <Button type="link">
                                <CgMenu color="#fff" fontSize={20} />
                            </Button>
                        </div>
                    </Toolbar>
                </AppBar>
            </Box>
        </Container>
    );
}

export default Header;
