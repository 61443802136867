import React from "react";
import { Container, Typography } from "@mui/material";
import styles from "../styles/styles";

function Footer(props) {
    const classes = styles();
    return (
        <div className={classes.footer}>
            <Container
                maxWidth="sm"
                className="d-flex align-items-center flex-column justify-content-center h-100"
            >
                <Typography
                    variant="subtitle2"
                    className={classes.footerContent}
                >
                    &copy; 2021 Tracerchain All Rights Reserved
                </Typography>
            </Container>
        </div>
    );
}

export default Footer;
