import React, { useEffect } from "react";
import "antd/dist/antd.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// local imports
import { Footer } from "./components/common-components";
import About from "./components/AboutUs";
import Contact from "./components/Contact";
import Features from "./components/Features";
import Home from "./components/Home";
import Integration from "./components/Integration";
import Pricing from "./components/Pricing";
import Services from "./components/Services";
import Team from "./components/Team";
import Technical from "./components/Technical";
import UserInterface from "./components/UserInterface";
import "animate.css/animate.min.css";

const theme = createTheme();
const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

function App() {
    const { i18n } = useTranslation();

    useEffect(() => {
        const lang = navigator.language;
        if (lang === "fr") i18n.changeLanguage("fr");

        ReactGA.initialize(GA_TRACKING_ID, {
            cookieDomain: "none",
        });
        ReactGA.pageview(window.location.pathname);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <Home />
                <Features />
                <UserInterface />
                <Integration />
                <Technical />
                <Pricing />
                <Services />
                <Contact />
                <About />
                <Team />
                <Footer />
                {/* <BackTop /> */}
                <CookieConsent
                    enableDeclineButton
                    flipButtons
                    // debug={true}
                    style={{
                        background: "rgb(17,45,88, 0.9)",
                        justifyContent: "center",
                        zIndex: 100,
                    }}
                    containerClasses="p-3 d-flex justify-content-center"
                    buttonStyle={{
                        background: "transparent",
                        border: "1px solid orange",
                        borderRadius: 4,
                        color: "#eeeeee",
                        fontSize: 14,
                    }}
                    contentStyle={{
                        textAlign: "left",
                        paddingLeft: "1em",
                        color: "#eeeeee",
                        fontSize: 14,
                    }}
                    declineButtonStyle={{ visibility: "hidden" }}
                    // overlay={true}
                >
                    <div>
                        <span></span>
                        This site uses cookies to offer you a better browsing
                        experience.
                    </div>
                </CookieConsent>
            </ThemeProvider>
        </div>
    );
}

export default App;
