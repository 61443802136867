import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { ApolloProvider } from "@apollo/client";
import createClient from "./utils/apolloClient";
import "./utils/i18n";
import { CircularProgress, Backdrop } from "@mui/material";

const client = createClient();

const loading = () => {
    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
            }}
        >
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
            >
                <CircularProgress style={{ color: "orange" }} />
            </Backdrop>
        </div>
    );
};
ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={loading()}>
            <ApolloProvider client={client}>
                <App />
            </ApolloProvider>
        </Suspense>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
