import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Paper, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

// icons
import { AiFillDashboard } from "react-icons/ai";
import { BsFillBarChartLineFill } from "react-icons/bs";
import { FaLevelUpAlt, FaCertificate } from "react-icons/fa";
import { ImUsers } from "react-icons/im";
import { RiOrganizationChart } from "react-icons/ri";
import { SiDatabricks, SiHandshake } from "react-icons/si";

// local imports
import styles from "./styles/styles";

function Features(props) {
    const classes = styles();
    const { t } = useTranslation();

    return (
        <Container maxWidth="lg" className={classes.featureCont} id="features">
            <svg width="0" height="0">
                <linearGradient
                    id="orange-gradient"
                    x1="100%"
                    y1="100%"
                    x2="0%"
                    y2="0%"
                >
                    <stop stopColor="#E76E59" offset="0%" />
                    <stop stopColor="orange" offset="100%" />
                </linearGradient>
            </svg>
            <ScrollAnimation animateIn="fadeInUp" duration={1}>
                <Typography variant="h4" className={classes.featureHeader}>
                    Our <span className={classes.font700}> Features</span>
                </Typography>{" "}
                <div className="d-flex justify-content-center">
                    <div className={classes.featureBorder} />
                </div>
            </ScrollAnimation>

            <Grid container spacing={4} className={classes.featGrid}>
                <Grid item xs={9} sm={6} md={4} lg={3}>
                    <ScrollAnimation animateIn="fadeInUp" duration={1}>
                        <Paper
                            elevation={0}
                            className={classes.feature}
                            // style={{ background: "#F9F8F8" }}
                        >
                            <RiOrganizationChart
                                fontSize={40}
                                className={classes.gradientIcon}
                            />
                            <ScrollAnimation
                                animateIn="fadeIn"
                                duration={2}
                                delay={4}
                            >
                                <Typography
                                    className={classes.featurePrimary}
                                    variant="h6"
                                    align="left"
                                >
                                    {t("feature.a.title")}
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle2"
                                    className={classes.featureSecondary}
                                >
                                    {t("feature.a.desc")}
                                </Typography>
                            </ScrollAnimation>
                        </Paper>
                    </ScrollAnimation>
                </Grid>
                <Grid item xs={9} sm={6} md={4} lg={3}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        duration={1}
                        delay={200}
                    >
                        <Paper className={classes.feature} elevation={0}>
                            <ImUsers
                                fontSize={40}
                                className={classes.gradientIcon}
                            />
                            <ScrollAnimation animateIn="fadeIn" duration={1}>
                                <Typography
                                    className={classes.featurePrimary}
                                    variant="h6"
                                    align="left"
                                >
                                    {t("feature.b.title")}
                                </Typography>
                                <Typography
                                    align="left"
                                    variant="subtitle2"
                                    className={classes.featureSecondary}
                                >
                                    {t("feature.b.desc")}
                                </Typography>
                            </ScrollAnimation>
                        </Paper>
                    </ScrollAnimation>
                </Grid>
                <Grid item xs={9} sm={6} md={4} lg={3}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        duration={1}
                        delay={250}
                    >
                        <Paper
                            elevation={0}
                            className={classes.feature}
                            // style={{ background: "#F9F8F8" }}
                        >
                            <SiHandshake
                                fontSize={40}
                                className={classes.gradientIcon}
                            />
                            <Typography
                                className={classes.featurePrimary}
                                variant="h6"
                                align="left"
                            >
                                {t("feature.c.title")}
                            </Typography>
                            <Typography
                                align="left"
                                variant="subtitle2"
                                className={classes.featureSecondary}
                            >
                                {t("feature.c.desc")} <br />
                                • Client <br />
                                • Supplier <br />• Auditor
                            </Typography>
                        </Paper>
                    </ScrollAnimation>
                </Grid>
                <Grid item xs={9} sm={6} md={4} lg={3}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        duration={1}
                        delay={300}
                    >
                        <Paper className={classes.feature} elevation={0}>
                            <FaCertificate
                                fontSize={40}
                                className={classes.gradientIcon}
                            />
                            <Typography
                                className={classes.featurePrimary}
                                variant="h6"
                                align="left"
                            >
                                Certifications
                            </Typography>
                            <Typography
                                align="left"
                                variant="subtitle2"
                                className={classes.featureSecondary}
                            >
                                Allows you to manage all the metadata related to
                                certifications. Within this feature you can
                                create linked data matrices: certification
                                documents and control questionnaires.
                            </Typography>
                        </Paper>
                    </ScrollAnimation>
                </Grid>
                <Grid item xs={9} sm={6} md={4} lg={3}>
                    <ScrollAnimation animateIn="fadeInUp" duration={1}>
                        <Paper
                            elevation={0}
                            className={classes.feature}
                            // style={{ background: "#FEFBF4" }}
                        >
                            <FaLevelUpAlt
                                fontSize={40}
                                className={classes.gradientIcon}
                            />
                            <Typography
                                className={classes.featurePrimary}
                                variant="h6"
                                align="left"
                            >
                                Transactions
                            </Typography>
                            <Typography
                                align="left"
                                variant="subtitle2"
                                className={classes.featureSecondary}
                            >
                                A transaction brings together all possible
                                actions from production, purchasing, processing,
                                sales, transportation, and analysis of raw
                                materials, semi-finished products and finished
                                products.
                            </Typography>
                        </Paper>
                    </ScrollAnimation>
                </Grid>
                <Grid item xs={9} sm={6} md={4} lg={3}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        duration={1}
                        delay={200}
                    >
                        <Paper className={classes.feature} elevation={0}>
                            <SiDatabricks
                                fontSize={40}
                                className={classes.gradientIcon}
                            />
                            <Typography
                                className={classes.featurePrimary}
                                variant="h6"
                                align="left"
                            >
                                Historical Data
                            </Typography>
                            <Typography
                                align="left"
                                variant="subtitle2"
                                className={classes.featureSecondary}
                            >
                                Find your transactions according to your search
                                criteria: by period, certification level, type
                                of materials or products, transaction metadata.
                            </Typography>
                        </Paper>
                    </ScrollAnimation>
                </Grid>
                <Grid item xs={9} sm={6} md={4} lg={3}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        duration={1}
                        delay={250}
                    >
                        <Paper
                            elevation={0}
                            className={classes.feature}
                            // style={{ background: "#FEFBF4" }}
                        >
                            <BsFillBarChartLineFill
                                fontSize={40}
                                className={classes.gradientIcon}
                            />
                            <Typography
                                className={classes.featurePrimary}
                                variant="h6"
                                align="left"
                            >
                                Inventory
                            </Typography>
                            <Typography
                                align="left"
                                variant="subtitle2"
                                className={classes.featureSecondary}
                            >
                                Manage your inventory based on valuable insights{" "}
                                <br />• Easy visualization of your level of
                                compliance <br />
                                • Generate periodic reports
                                <br /> • Dynamic query system
                            </Typography>
                        </Paper>
                    </ScrollAnimation>
                </Grid>
                <Grid item xs={9} sm={6} md={4} lg={3}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        duration={1}
                        delay={300}
                    >
                        <Paper className={classes.feature} elevation={0}>
                            <AiFillDashboard
                                fontSize={40}
                                className={classes.gradientIcon}
                            />
                            <Typography
                                className={classes.featurePrimary}
                                variant="h6"
                                align="left"
                            >
                                Dashboard
                            </Typography>
                            <Typography
                                align="left"
                                variant="subtitle2"
                                className={classes.featureSecondary}
                            >
                                Create a customizable visualization of
                                Tracerchain data summaries:
                                <br />
                                • Last transactions made <br />
                                • Pending transactions
                                <br /> • Notifications
                            </Typography>
                        </Paper>
                    </ScrollAnimation>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Features;
