import React from "react";
import clsx from "clsx";
import ScrollAnimation from "react-animate-on-scroll";
import { Button } from "antd";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// local imports
import styles from "./styles/styles";

function Pricing(props) {
    const classes = styles();
    const { t } = useTranslation();

    const trans = (name) => t(`pricing.${name}`);

    return (
        <div id="pricing" className={classes.pricingCont}>
            <Container maxWidth="lg" className={classes.pRoot}>
                <ScrollAnimation animateIn="fadeInUp" duration={1}>
                    <Typography variant="h4" className={classes.featureHeader}>
                        <span className={classes.font700}>
                            {trans("highlightTitle")}
                        </span>{" "}
                        {trans("titleExtension")}
                    </Typography>
                    <div className="d-flex justify-content-center">
                        <div className={classes.pricingBorder} />
                    </div>
                </ScrollAnimation>

                <Container maxWidth="xs">
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        duration={1}
                        delay={200}
                    >
                        <Typography
                            variant="subtitle2"
                            className={classes.pricingSecondary}
                            align="center"
                        >
                            {trans("description")}
                        </Typography>
                    </ScrollAnimation>
                </Container>
                <Grid
                    container
                    spacing={4}
                    className="d-flex justify-content-center mt-4"
                >
                    {["a", "b", "c", "d"].map((item, id) => {
                        let features = [
                            "feature1",
                            "feature2",
                            "feature3",
                            "feature4",
                        ];
                        features =
                            item !== "c" ? [...features, "feature5"] : features;
                        return (
                            <Grid item xs={9} sm={5} md={3} key={id}>
                                <ScrollAnimation
                                    animateIn="fadeIn"
                                    duration={1}
                                    delay={
                                        {
                                            0: 0,
                                            1: 100,
                                            2: 150,
                                            3: 200,
                                        }[id]
                                    }
                                >
                                    <Paper
                                        elevation={0}
                                        className={classes.pricing}
                                        key={id}
                                    >
                                        <div className={classes.pricingHeader}>
                                            <div>
                                                <div className={classes.card}>
                                                    <div>
                                                        <Typography
                                                            variant="h6"
                                                            className={
                                                                classes.pricingPrimary
                                                            }
                                                        >
                                                            {trans(
                                                                `plan.${item}.title`
                                                            ).toUpperCase()}
                                                        </Typography>
                                                        <div className="d-flex justify-content-center">
                                                            <div
                                                                className={
                                                                    classes.primaryBorder
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        classes.pricingDesc
                                                    }
                                                >
                                                    {features.map(
                                                        (feat, idx) => {
                                                            return (
                                                                <Typography
                                                                    key={idx}
                                                                    variant="body2"
                                                                    className={clsx(
                                                                        classes.item,
                                                                        idx !==
                                                                            0
                                                                            ? "mt-3"
                                                                            : ""
                                                                    )}
                                                                >
                                                                    {trans(
                                                                        `plan.${item}.${feat}`
                                                                    )}
                                                                </Typography>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                            <div className={classes.planCont}>
                                                <Button
                                                    className={classes.planBtn}
                                                >
                                                    {id === 0
                                                        ? "Get Started"
                                                        : "Contact Us"}
                                                    {/* {trans("choosePlan")} */}
                                                </Button>
                                            </div>
                                        </div>
                                    </Paper>
                                </ScrollAnimation>
                            </Grid>
                        );
                    })}
                </Grid>
            </Container>
        </div>
    );
}

export default Pricing;
