import blob from "../../images/blob6.svg";
import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
    style: {
        width: "100%",
        base: {
            color: "#32325d",
            // color: '#E0E0E0',
            fontFamily: "Arial, sans-serif",
            fontSmoothing: "antialiased",
            fontSize: "14px",
            "::placeholder": {
                // color: '#bdbdbd',
                // fontSize: '0.9em',
            },
            background: "transparent",
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },

    // header
    appbar: {
        background: "transparent !important",
    },
    menus: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("lg")]: {
            display: "none",
        },
    },
    menuBtn: {
        [theme.breakpoints.up("xl")]: {
            display: "none",
        },
    },
    menu: {
        color: "#fff",
        fontSize: 16,
    },
    // end of header

    bg1: {},
    //, home
    homeCont: {
        background: "linear-gradient(to right, #112B56, #05618F)",
        height: "100vh",
        overflowX: "hidden",
    },
    carousel: {
        height: "86vh",
        display: "flex",
        alignItems: "center",
    },
    homebg1: {
        [theme.breakpoints.only("md")]: {
            height: "460px",
            width: "auto",
        },
        [theme.breakpoints.only("sm")]: {
            height: "auto",
            width: "100%",
        },
        [theme.breakpoints.only("xs")]: {
            height: "auto",
            width: "100%",
            marginTop: "1em",
        },
    },
    slide1: {
        color: "#fff",
        fontWeight: "700 !important",
        lineHeight: "1.4 !important",
        [theme.breakpoints.only("sm")]: {
            fontSize: "36px !important",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "24px !important",
        },
    },
    slide2: {
        color: "#fff",
        fontWeight: "700 !important",
        lineHeight: "1.4 !important",
        fontSize: "40px !important",
        [theme.breakpoints.only("sm")]: {
            fontSize: "32px !important",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "24px !important",
        },
    },
    slide3: {
        color: "#fff",
        fontWeight: "700 !important",
        lineHeight: "1.5 !important",
        [theme.breakpoints.only("sm")]: {
            fontSize: "36px !important",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "24px !important",
        },
    },
    slide3Desc: {
        color: "#fff",
        marginTop: "1em !important",
    },
    orange: {
        color: "#EF901A !important",
    },
    // end of home

    // features styles
    featureCont: {
        background: "#fff",
        padding: "1em 0",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    },
    featureHeader: {
        color: "#010F54",
        margin: "0.5em 0 1em 0",
    },
    featureBorder: {
        width: "120px",
        height: "3px",
        background: "linear-gradient(to right, #E76E59, orange)",
        marginTop: "-1.5em",
    },
    featGrid: {
        marginTop: "5px !important",
        [theme.breakpoints.only("xs")]: {
            display: "flex",
            justifyContent: "center",
        },
    },
    feature: {
        height: "305px",
        boxShadow: "0px 1px 19px -6px rgba(140,162,250,0.31) !important",
        // boxShadow: "-1px 3px 20px 0px rgba(109,109,109,0.15) !important",
        // boxShadow: "3px 1px 18px -6px #B1B1B1 !important",
        // boxShadow: "0px -18px 85px -59px rgba(109,109,109,0.17) !important",
        borderRadius: 5,
        padding: "2em",
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        border: "1px solid #F3F2F2 !important",
        "&:hover": {
            transform: "scale(1.05)",
            // background: "#FEFBF4",
        },
    },
    featurePrimary: {
        color: "#1344A5 !important",
        fontWeight: "700 !important",
        marginTop: "0.5em !important",
    },
    featureSecondary: {
        // color: "#035F91",
        color: "#6C757D !important",
        marginTop: "1em !important",
    },
    gradientIcon: {
        fill: "url(#orange-gradient)",
    },
    font700: {
        fontWeight: 700,
    },
    // end of feature styles

    //user interface styles
    interfaceCont: {
        background: "#FEFBF4",
        marginTop: "3em",
        padding: "4em 0",
    },
    interface: {
        fontWeight: 700,
        color: "#1344A5",
        // color: "#010F54",
    },
    interfaceHeader: {
        color: "#010F54",
        margin: "0.5em 0 1em 0",
        [theme.breakpoints.only("xs")]: {
            fontSize: "32px !important",
        },
    },
    screenshot: {
        boxShadow: "1px 1px 26px -6px rgba(0,0,0,0.58)",
    },
    // end of interface styles

    // intergration
    integrationCont: {
        [theme.breakpoints.up("md")]: {
            height: "60vh",
        },
        padding: "3em 0 0 0",
        [theme.breakpoints.only("sm")]: {
            padding: "3em 0 4em 0",
        },
        // padding: "4em 0 5em 0",
    },
    apiSmUp: {
        [theme.breakpoints.up("sm")]: {
            display: "flex",
            justifyContent: "flex-start",
        },
        [theme.breakpoints.only("xs")]: {
            display: "none",
        },
    },
    apiXs: {
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
        [theme.breakpoints.only("xs")]: {
            display: "flex",
            justifyContent: "flex-start",
        },
    },
    integratioHeader: {
        color: "#010F54",
        fontWeight: "bold",
    },
    integBorder: {
        width: "180px",
        height: "3px",
        background: "linear-gradient(to right, #E76E59, orange)",
        marginTop: "0.5em",
    },
    integration: {
        paddingTop: "1.5em",
        // color: "#010E55",
        color: "#6C757D",
    },
    api: {
        [theme.breakpoints.down("md")]: {
            height: "auto",
            width: "100%",
        },
        [theme.breakpoints.only("xs")]: {
            height: "auto",
            width: "300px",
        },
    },
    // end of integration

    // technical
    technicalCont: {
        background: "linear-gradient(to right, #112B56, #05618F)",
        padding: "4em 0",
        [theme.breakpoints.up("sm")]: {
            height: "60vh",
        },
    },
    technicalHeader: {
        color: "#EF901A",
        fontWeight: 600,
        letterSpacing: "4px !important",
    },
    technicalDesc: {
        color: "#fff",
        marginTop: "1em",
        letterSpacing: "1px !important",
    },
    technical: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    },
    technology: {
        marginTop: "1.5em !important",
        color: "#fff",
        letterSpacing: "3px !important",
    },
    graphql: {
        marginTop: "-5px",
    },
    // end of technical

    // pricing
    pricingCont: {
        // height: "100vh",
        background: "#fff",
        backgroundImage: `url(${blob})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    },
    pRoot: {
        padding: "3em 0em",
    },
    pricingBorder: {
        width: "150px",
        height: "3px",
        background: "linear-gradient(to right, #E76E59, orange)",
        marginTop: "-1.5em",
    },
    pricingSecondary: {
        // color: "#9DA9BB",
        color: "#6C757D",
        marginTop: "1em !important",
    },
    pricing: {
        border: "1px solid #EEF0F8",
        height: "480px",
        borderRadius: 5,
        paddingBottom: "1em",
        boxShadow: "3px 1px 18px -6px #B1B1B1 !important",
    },
    pricingHeader: {
        display: "flex",
        alignContent: "space-between",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: 10,
        height: "100%",
    },
    card: {
        background: "linear-gradient(to right, #112B56, #05618F)",
        height: "90px",
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    pricingPrimary: {
        color: "#fff",
        fontWeight: 600,
    },
    primaryBorder: {
        width: "60px",
        height: "2px",
        marginTop: "5px",
        background: "linear-gradient(to right, #E76E59, orange)",
    },
    pricingDesc: {
        marginTop: "1.5em",
        padding: "1em",
    },
    item: {
        color: "#6C757D",
        // color: "#010E55"
    },
    planCont: {
        padding: "0 2em",
    },
    planBtn: {
        background: "linear-gradient(to bottom, #E76E59, #E17C20)",
        color: "#fff",
        borderRadius: 5,
        width: "100%",
    },
    // end of pricing

    // services
    serviceCont: {
        // background: "#F9F9FB",
        background: "#FEFBF4",
        [theme.breakpoints.up("sm")]: {
            height: "60vh",
        },
        [theme.breakpoints.only("xs")]: {
            padding: "2em 0",
        },
        // padding: "2em 0",
    },
    serviceAvatar: {
        background: "#EBF3FC",
        // background:
        //     "linear-gradient(to right, #112B56, #05618F)",
        // background:
        //     "linear-gradient(to right, #E76E59, orange)",
        // background:
        //     "linear-gradient(to bottom, #E76E59, #E17C20)",
        border: "3px solid #fff",
        boxShadow: "3px 1px 18px -6px #B1B1B1",
    },
    service: {
        // color: "#FB542B",
        // color: "#EF901A",
        // fontWeight: 700,
        color: "#010F54",
        // fontSize: 18,
        marginTop: "1.5em !important",
        lineHeight: "1.4 !important",
    },
    serviceBorder: {
        width: "150px",
        height: "3px",
        background: "linear-gradient(to right, #E76E59, orange)",
        marginTop: "0.5em",
    },
    serviceIcon: {
        fill: "url(#orange-gradient)",
        fontSize: 50,
    },
    settingIcon: {
        fill: "url(#orange-gradient)",
        fontSize: 40,
    },
    supportIcon: {
        marginTop: "-5px",
        fill: "url(#orange-gradient)",
        fontSize: 50,
    },
    // end of services

    // contact
    contactHeader: {
        color: "#010F54",
    },
    contactSecondary: {
        color: "#6C757D",
        marginTop: "1em !important",
    },
    headerBorder: {
        width: "150px",
        height: "3px",
        background: "linear-gradient(to right, #E76E59, orange)",
        marginTop: "0.5em",
    },
    input: {
        background: "#F8F9F8",
    },
    contactBtn: {
        background: "linear-gradient(to bottom, #E76E59, #E17C20)",
        color: "#fff",
        borderRadius: 4,
        padding: "0 2em",
    },
    contactImg: {
        [theme.breakpoints.down("md")]: {
            height: "auto",
            width: "100%",
        },
        [theme.breakpoints.only("xs")]: {
            display: "none",
        },
    },
    // end of contact

    // about
    aboutCont: {
        background: "linear-gradient(to right, #112B56, #05618F)",
        padding: "4em 3em 6em 3em",
    },
    aboutHeader: {
        color: "#fff",
        margin: "0.5em 0 1em 0",
    },
    aboutBorder: {
        width: "150px",
        height: "3px",
        background: "linear-gradient(to right, #E76E59, orange)",
        marginTop: "-1.5em",
    },
    aboutDesc: {
        color: "#fff",
        marginTop: "2.5em !important",
    },
    services: {
        marginTop: "3em",
    },
    solAvatar: {
        background: "linear-gradient(to right, #E76E59, orange)",
        boxShadow: "3px 15px 19px -6px #1F1F20",

        border: "3px solid #fff",
    },
    solIcon: {
        color: "#fff",
        fontSize: 40,
        marginTop: "-7px",
    },
    solution: {
        color: "#EEF3F6",
        marginTop: "0.5em !important",
    },
    // end of about-us styles`

    // our-team
    teamCont: {
        padding: "4em 0",
        background: "#fff",
    },
    teamBorder: {
        width: "110px",
        height: "3px",
        background: "linear-gradient(to right, #E76E59, orange)",
        marginTop: "-1.5em",
    },
    teamDesc: {
        // color: "#9DA9BB",
        color: "#6C757D",
        marginTop: "2em !important",
    },
    // our-team

    // footer
    footer: {
        height: "13vh",
        // background: "#F8F9FA",
        background: "#FEFBF4",
        // background: "linear-gradient(to right, #112B56, #05618F)",
    },
    footerContent: {
        color: "#6C757D",
        fontWeight: 500,
        // color: "#f5f5fa",
    },
    // end of footer

    flag: {
        fontSize: 24,
        margin: "5px 0 0 10px",
    },
    flagMenu: {
        marginRight: "5px",
    },
    expandFlag: {
        marginLeft: "5px",
    },
    white: {
        color: "#fff",
    },
    backTop: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    arrowAvatar: {
        background: "linear-gradient(to right, #E76E59, orange)",
        marginLeft: "3em",
        marginTop: "-3.5em",
        boxShadow: "3px 1px 18px -6px #B1B1B1 !important",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.only("md")]: {
            marginLeft: "3em",
        },
    },

    // cookie
    cookieCont: {
        background: "rgb(17,45,88, 0.9)",
        justifyContent: "center",
        zIndex: 100,
    },
}));

export default styles;
