import React from "react";
import clsx from "clsx";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";
import { Container, Typography, Grid } from "@mui/material";

// local imports
import eth from "../images/eth.png";
import graphql from "../images/graphql.png";
import python from "../images/python.png";
import react from "../images/react.png";
import solidity from "../images/solidity.png";
import styles from "./styles/styles";

function Technical(props) {
    const classes = styles();
    const { t } = useTranslation();

    const trans = (name) => t(`technical.${name}`);

    return (
        <div id="technical" className={classes.technicalCont}>
            <Container maxWidth="lg">
                <ScrollAnimation animateIn="fadeInUp" duration={1}>
                    <Typography
                        variant="h4"
                        className={clsx(
                            classes.technicalHeader,
                            "technical-text"
                        )}
                    >
                        {trans("title")}
                    </Typography>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" duration={1} delay={200}>
                    <Typography
                        variant="body2"
                        className={classes.technicalDesc}
                    >
                        {trans("description")}
                    </Typography>
                </ScrollAnimation>
                <Grid container columns={15} className="mt-4 p-4" spacing={2}>
                    <Grid
                        item
                        xs={5}
                        sm={3}
                        className="d-flex align-items-center flex-column justify-content-center"
                    >
                        <ScrollAnimation animateIn="fadeInUp" duration={1}>
                            <img
                                alt="ethereum"
                                src={eth}
                                height="120px"
                                width="auto"
                            />
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInUp" duration={1}>
                            <Typography
                                variant="subtitle1"
                                className={classes.technology}
                            >
                                {trans("eth")}
                            </Typography>
                        </ScrollAnimation>
                    </Grid>
                    <Grid
                        item
                        xs={5}
                        sm={3}
                        className="d-flex align-items-center flex-column justify-content-center"
                    >
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            duration={1}
                            delay={200}
                        >
                            <img
                                alt="solidity"
                                src={solidity}
                                height="120px"
                                width="auto"
                            />
                        </ScrollAnimation>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            duration={1}
                            delay={200}
                        >
                            <Typography
                                variant="subtitle1"
                                className={classes.technology}
                            >
                                {trans("solidity")}
                            </Typography>
                        </ScrollAnimation>
                    </Grid>
                    <Grid
                        item
                        xs={5}
                        sm={3}
                        className="d-flex align-items-center flex-column justify-content-center"
                    >
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            duration={1}
                            delay={250}
                        >
                            <img
                                alt="python"
                                src={python}
                                height="120px"
                                width="auto"
                            />
                        </ScrollAnimation>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            duration={1}
                            delay={250}
                        >
                            <Typography
                                variant="subtitle1"
                                className={classes.technology}
                            >
                                {trans("python")}
                            </Typography>
                        </ScrollAnimation>
                    </Grid>
                    <Grid
                        item
                        xs={7}
                        sm={3}
                        className="d-flex align-items-center flex-column justify-content-center"
                    >
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            duration={1}
                            delay={300}
                        >
                            <img
                                alt="react"
                                className="mb-3"
                                src={react}
                                height="100px"
                                width="auto"
                            />
                        </ScrollAnimation>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            duration={1}
                            delay={300}
                        >
                            <Typography
                                variant="subtitle1"
                                className={classes.technology}
                            >
                                {trans("react")}
                            </Typography>
                        </ScrollAnimation>
                    </Grid>
                    <Grid
                        item
                        xs={7}
                        sm={3}
                        className="d-flex align-items-center flex-column justify-content-center"
                    >
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            duration={1}
                            delay={350}
                        >
                            <img
                                alt="graphql"
                                className={classes.graphql}
                                src={graphql}
                                height="120px"
                                width="auto"
                            />
                        </ScrollAnimation>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            duration={1}
                            delay={350}
                        >
                            <Typography
                                variant="subtitle1"
                                className={classes.technology}
                            >
                                {trans("graphql")}
                            </Typography>
                        </ScrollAnimation>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default Technical;
