import React from "react";
import CountryFlag from "react-country-flag";
import { MdOutlineExpandMore } from "react-icons/md";
import { Menu, Dropdown } from "antd";
import { useTranslation } from "react-i18next";

// local imports
import styles from "../styles/styles";

function Language(props) {
    const classes = styles();
    const { i18n } = useTranslation();

    const changeLanguage = (selectedLang) => {
        i18n.changeLanguage(selectedLang);
    };

    const langs = [
        { code: "US", label: "EN" },
        { code: "FR", label: "FR" },
    ];

    const lang = localStorage.getItem("i18nextLng");

    return (
        <div>
            <Dropdown
                arrow
                placement="bottomCenter"
                overlay={() => (
                    <Menu>
                        {langs.map((item, idx) => {
                            const code = item.code === "US" ? "en" : "fr";
                            return (
                                <Menu.Item
                                    key={idx}
                                    onClick={() => changeLanguage(code)}
                                >
                                    <CountryFlag
                                        svg
                                        countryCode={item.code}
                                        className={classes.flagMenu}
                                    />
                                    {item.label}
                                </Menu.Item>
                            );
                        })}
                    </Menu>
                )}
            >
                <CountryFlag
                    svg
                    className={classes.flag}
                    countryCode={lang.startsWith("en") ? "US" : "FR"}
                />
            </Dropdown>
            <MdOutlineExpandMore fontSize={10} className={classes.expandFlag} />
        </div>
    );
}

export default Language;
