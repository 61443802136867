import React from "react";
import clsx from "clsx";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Typography, Grid } from "@mui/material";
import { Image } from "antd";
import { useTranslation } from "react-i18next";

// local imports
import styles from "./styles/styles";
import sc1 from "../images/sc1.png";
import sc2 from "../images/sc2.png";
import sc3 from "../images/sc3.png";
function UserInterface(props) {
    const classes = styles();
    const { t } = useTranslation();

    const trans = (name) => t(`interface.${name}`);

    return (
        <div className={classes.interfaceCont} id="user-interface">
            <Container maxWidth="lg">
                <ScrollAnimation animateIn="fadeInUp" duration={1}>
                    <Typography
                        variant="h3"
                        className={classes.interfaceHeader}
                    >
                        {trans("title")} <br />
                        <span className={classes.interface}>
                            {trans("highlightTitle")}{" "}
                        </span>
                        {trans("titleExtension")}
                    </Typography>
                </ScrollAnimation>

                <Container maxWidth="sm">
                    <ScrollAnimation animateIn="fadeInUp" duration={1}>
                        <Typography
                            variant="subtitle1"
                            className={clsx(classes.featureSecondary, "mt-2")}
                            align="center"
                        >
                            {trans("caption")}
                        </Typography>
                    </ScrollAnimation>
                </Container>

                <Grid
                    container
                    spacing={2}
                    className="pt-4 mt-4 mb-4"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={3}>
                        <ScrollAnimation animateIn="fadeInUp" duration={1}>
                            <Image
                                alt="tracerchain-screenshot"
                                src={sc1}
                                height="200px"
                                width="auto"
                                className={classes.screenshot}
                            />
                        </ScrollAnimation>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            duration={1}
                            delay={200}
                        >
                            <Image
                                alt="tracerchain-screenshot"
                                src={sc2}
                                width="100%"
                                height="auto"
                                className={classes.screenshot}
                            />
                        </ScrollAnimation>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            duration={1}
                            delay={250}
                        >
                            <Image
                                src={sc3}
                                height="200px"
                                className={classes.screenshot}
                            />
                        </ScrollAnimation>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default UserInterface;
