import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Avatar } from "antd";
import { Container, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

// local imports
import styles from "./styles/styles";
import { FaConnectdevelop, FaTools } from "react-icons/fa";
import { MdAssistantPhoto, MdOutlineSupport } from "react-icons/md";
import { MdOutlineIntegrationInstructions } from "react-icons/md";

function Technical(props) {
    const classes = styles();
    const { t } = useTranslation();
    const trans = (name) => t(`services.${name}`);

    return (
        <div id="services" className={classes.serviceCont}>
            <Container
                maxWidth="lg"
                className="h-100 d-flex align-items-center flex-column justify-content-center"
            >
                <ScrollAnimation animateIn="fadeInUp" duration={1}>
                    <Typography variant="h4">
                        {trans("title")}{" "}
                        <span className={classes.font700}>
                            {trans("highlightTitle")}
                        </span>
                    </Typography>
                    <div className="d-flex justify-content-center">
                        <div className={classes.serviceBorder} />
                    </div>
                </ScrollAnimation>
                <Container maxWidth="xs">
                    <ScrollAnimation animateIn="fadeInUp" duration={1}>
                        <Typography
                            variant="subtitle2"
                            align="center"
                            className={classes.pricingSecondary}
                        >
                            {trans("description")}
                        </Typography>
                    </ScrollAnimation>
                </Container>
                <Grid container spacing={2} columns={15} className="mt-4">
                    {["a", "b", "c", "d", "e"].map((item, idx) => {
                        return (
                            <Grid item xs={5} sm={3} key={idx}>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    duration={1}
                                    delay={
                                        {
                                            0: 0,
                                            1: 200,
                                            2: 230,
                                            3: 260,
                                            4: 290,
                                        }[idx]
                                    }
                                >
                                    <Avatar
                                        size={90}
                                        className={classes.serviceAvatar}
                                    >
                                        {idx === 0 ? (
                                            <MdAssistantPhoto
                                                className={classes.serviceIcon}
                                            />
                                        ) : idx === 1 ? (
                                            <MdOutlineIntegrationInstructions
                                                className={classes.serviceIcon}
                                            />
                                        ) : idx === 2 ? (
                                            <FaTools
                                                className={classes.settingIcon}
                                            />
                                        ) : idx === 3 ? (
                                            <MdOutlineSupport
                                                className={classes.supportIcon}
                                            />
                                        ) : (
                                            <FaConnectdevelop
                                                className={classes.serviceIcon}
                                            />
                                        )}
                                    </Avatar>

                                    <Typography
                                        variant="subtitle1"
                                        className={classes.service}
                                    >
                                        {trans(`list.${item}`)}
                                    </Typography>
                                </ScrollAnimation>
                            </Grid>
                        );
                    })}
                </Grid>
            </Container>
        </div>
    );
}

export default Technical;
