import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import clsx from "clsx";
// import Slider from "react-slick";
import { Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// local imports
import styles from "./styles/styles";
// import sample from "../images/manoa.PNG";

function Team(props) {
    const classes = styles();
    const { t } = useTranslation();
    const trans = (name) => t(`team.${name}`);

    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     speed: 750,
    //     autoplaySpeed: 3000,
    //     cssEase: "linear",
    // };

    return (
        <div className={classes.teamCont}>
            <Container maxWidth="lg">
                <ScrollAnimation animateIn="fadeInUp" duration={1}>
                    <Typography variant="h4" className={classes.featureHeader}>
                        {trans("title")}{" "}
                        <span className={classes.font700}>
                            {trans("highlightTitle")}
                        </span>
                    </Typography>
                    <div className="d-flex justify-content-center">
                        <div className={classes.teamBorder} />
                    </div>
                </ScrollAnimation>

                <Container maxWidth="md">
                    <ScrollAnimation animateIn="fadeInUp" duration={1}>
                        <Typography
                            variant="body1"
                            className={classes.teamDesc}
                            align="justify"
                        >
                            {trans("description1")}
                        </Typography>
                        <Typography
                            variant="body1"
                            className={classes.teamDesc}
                            align="justify"
                        >
                            {trans("description2")}
                        </Typography>
                    </ScrollAnimation>
                </Container>

                {/* <div className="mt-4">
                    <Slider {...settings}>
                        <div className="slider">
                            <Paper
                                elevation={0}
                                style={{
                                    height: "380px",
                                    boxShadow: "3px 1px 18px -6px #B1B1B1",
                                    borderRadius: 5,
                                }}
                            >
                                <img
                                    src={sample}
                                    alt="avatar"
                                    width="100%"
                                    style={{
                                        borderTopRightRadius: 5,
                                        borderTopLeftRadius: 5,
                                    }}
                                />
                                <Typography
                                    variant="h6"
                                    className="mt-3"
                                    style={{
                                        fontWeight: 700,
                                        color: "#010F54",
                                    }}
                                >
                                    John Doe
                                </Typography>
                                <div className="d-flex justify-content-center">
                                    <div
                                        style={{
                                            width: "100px",
                                            height: "2px",
                                            background:
                                                "linear-gradient(to right, #E76E59, orange)",
                                        }}
                                    />
                                </div>
                                <Typography variant="caption" className="mt-2">
                                    React Developer
                                </Typography>
                            </Paper>
                        </div>
                        <div className="slider">
                            <Paper
                                elevation={0}
                                style={{
                                    height: "380px",
                                    boxShadow: "3px 1px 18px -6px #B1B1B1",
                                    borderRadius: 5,
                                    paddingBottom: "10em",
                                }}
                            >
                                <img
                                    src={sample}
                                    alt="avatar"
                                    width="100%"
                                    style={{
                                        borderTopRightRadius: 5,
                                        borderTopLeftRadius: 5,
                                    }}
                                />
                                <Typography
                                    variant="h6"
                                    className="mt-3"
                                    style={{
                                        fontWeight: 700,
                                        color: "#010F54",
                                    }}
                                >
                                    John Doe
                                </Typography>
                                <div className="d-flex justify-content-center">
                                    <div
                                        style={{
                                            width: "100px",
                                            height: "2px",
                                            background:
                                                "linear-gradient(to right, #E76E59, orange)",
                                        }}
                                    />
                                </div>
                                <Typography variant="caption" className="mt-2">
                                    React Developer
                                </Typography>
                            </Paper>
                        </div>{" "}
                        <div className="slider">
                            <Paper
                                elevation={0}
                                style={{
                                    height: "380px",
                                    boxShadow: "3px 1px 18px -6px #B1B1B1",
                                    borderRadius: 5,
                                    paddingBottom: "10em",
                                }}
                            >
                                <img
                                    src={sample}
                                    alt="avatar"
                                    width="100%"
                                    style={{
                                        borderTopRightRadius: 5,
                                        borderTopLeftRadius: 5,
                                    }}
                                />
                                <Typography
                                    variant="h6"
                                    className="mt-3"
                                    style={{
                                        fontWeight: 700,
                                        color: "#010F54",
                                    }}
                                >
                                    John Doe
                                </Typography>
                                <div className="d-flex justify-content-center">
                                    <div
                                        style={{
                                            width: "100px",
                                            height: "2px",
                                            background:
                                                "linear-gradient(to right, #E76E59, orange)",
                                        }}
                                    />
                                </div>
                                <Typography variant="caption" className="mt-2">
                                    React Developer
                                </Typography>
                            </Paper>
                        </div>
                        <div className="slider">
                            <Paper
                                elevation={0}
                                style={{
                                    height: "380px",
                                    boxShadow: "3px 1px 18px -6px #B1B1B1",
                                    borderRadius: 5,
                                    paddingBottom: "10em",
                                }}
                            >
                                <img
                                    src={sample}
                                    alt="avatar"
                                    width="100%"
                                    style={{
                                        borderTopRightRadius: 5,
                                        borderTopLeftRadius: 5,
                                    }}
                                />
                                <Typography
                                    variant="h6"
                                    className="mt-3"
                                    style={{
                                        fontWeight: 700,
                                        color: "#010F54",
                                    }}
                                >
                                    John Doe
                                </Typography>
                                <div className="d-flex justify-content-center">
                                    <div
                                        style={{
                                            width: "100px",
                                            height: "2px",
                                            background:
                                                "linear-gradient(to right, #E76E59, orange)",
                                        }}
                                    />
                                </div>
                                <Typography variant="caption" className="mt-2">
                                    React Developer
                                </Typography>
                            </Paper>
                        </div>
                        <div className="slider">
                            <Paper
                                elevation={0}
                                style={{
                                    height: "380px",
                                    boxShadow: "3px 1px 18px -6px #B1B1B1",
                                    borderRadius: 5,
                                    paddingBottom: "10em",
                                }}
                            >
                                <img
                                    src={sample}
                                    alt="avatar"
                                    width="100%"
                                    style={{
                                        borderTopRightRadius: 5,
                                        borderTopLeftRadius: 5,
                                    }}
                                />
                                <Typography
                                    variant="h6"
                                    className="mt-3"
                                    style={{
                                        fontWeight: 700,
                                        color: "#010F54",
                                    }}
                                >
                                    John Doe
                                </Typography>
                                <div className="d-flex justify-content-center">
                                    <div
                                        style={{
                                            width: "100px",
                                            height: "2px",
                                            background:
                                                "linear-gradient(to right, #E76E59, orange)",
                                        }}
                                    />
                                </div>
                                <Typography variant="caption" className="mt-2">
                                    React Developer
                                </Typography>
                            </Paper>
                        </div>
                    </Slider>
                </div> */}
            </Container>
        </div>
    );
}

export default Team;
